import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

import styles from "./travelRoutes.module.scss";
import gsap from "gsap";

const routes = [
  {
    imageURL: "/assets/g.png",
    title: "Маршрут з України до Німеччини",
    travelConditions: [
      "Пасажирський мікроавтобус",
      "Місць: 6-8",
      "Wi-Fi",
      "Кондиціонер",
      "Розетки",
      "Місце для багажу",
    ],
  },
  {
    imageURL: "/assets/bel.png",
    title: "Маршрут з України до Бельгії",
    travelConditions: [
      "Пасажирський мікроавтобус",
      "Місць: 6-8",
      "Wi-Fi",
      "Кондиціонер",
      "Розетки",
      "Місце для багажу",
    ],
  },
  {
    imageURL: "/assets/1.png",
    title: "Маршрут з України до Нідерландів",
    travelConditions: [
      "Пасажирський мікроавтобус",
      "Місць: 6-8",
      "Wi-Fi",
      "Кондиціонер",
      "Розетки",
      "Місце для багажу",
    ],
  },
  {
    imageURL: "/assets/2.png",
    title: "Маршрут з України до Швейцарії",
    travelConditions: [
      "Пасажирський мікроавтобус",
      "Місць: 6-8",
      "Wi-Fi",
      "Кондиціонер",
      "Розетки",
      "Місце для багажу",
    ],
  },
  {
    imageURL: "/assets/3.png",
    title: "Маршрут з України до Прибалтики ",
    travelConditions: [
      "Пасажирський мікроавтобус",
      "Місць: 6-8",
      "Wi-Fi",
      "Кондиціонер",
      "Розетки",
      "Місце для багажу",
    ],
  },
];

const TravelRoutes = ({ setIsShowFindOutThePricePopup }) => {
  const [initialSlide, setInitialSlide] = useState(0);
  const initialSlideHandler = (index) => {
    setInitialSlide(index);
  };

  const [afterChange, setAfterChange] = useState(0);
  const afterChangeHandler = (index) => {
    setAfterChange(index);
  };
  const [textHeight, setTextHeight] = useState(0);
  const textRef = useRef(null);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="nextArrow"
        onClick={onClick}
        style={{ top: `calc(${textHeight}px + 30px)` }}
      >
        <button>
          <BsChevronRight />
        </button>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="prevArrow"
        onClick={onClick}
        style={{ top: `calc(${textHeight}px + 30px)` }}
      >
        <button>
          <BsChevronLeft />
        </button>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    touchMove: true,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => initialSlideHandler(next),
    afterChange: (current) => afterChangeHandler(current),

    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setTextHeight(textRef.current.clientHeight);
    gsap.fromTo(
      "#travelRoutes",
      {
        duration: 0.7,
        opacity: 0,
        scale: 0.5,
      },
      {
        duration: 0.7,
        opacity: 1,
        scale: 1,
      }
    );
  }, []);
  return (
    <div className={styles.travelRoutes} id="travelRoutes">
      <div className="container">
        <div className={styles.text} ref={textRef}>
          <strong>наші Маршрути</strong>
          <h2>
            <span>Маршрути</span> пасажирських перевезень
          </h2>
        </div>
        <div className={styles.slider}>
          <Slider {...settings}>
            {routes.map((route, index) => (
              <div className={styles.travelItem} key={index}>
                <div className={styles.top}>
                  <img src={route.imageURL} alt="route" />
                  <div
                    className={styles.price}
                    onClick={() => setIsShowFindOutThePricePopup(true)}
                  >
                    Дізнатися ціну <BsChevronRight />
                  </div>
                </div>
                <div className={styles.bottom}>
                  <h3>{route.title}</h3>
                  <ul>
                    {route.travelConditions.map((condition, index) => (
                      <li key={index}>
                        {index === 0 && (
                          <img src="/assets/icons/star.svg" alt="" />
                        )}
                        {index === 1 && (
                          <img src="/assets/icons/person.svg" alt="" />
                        )}
                        {index === 2 && (
                          <img src="/assets/icons/wifi.svg" alt="" />
                        )}
                        {index === 3 && (
                          <img src="/assets/icons/conditioner.svg" alt="" />
                        )}
                        {index === 4 && (
                          <img src="/assets/icons/tv.svg" alt="" />
                        )}
                        {index === 5 && (
                          <img src="/assets/icons/luggage.svg" alt="" />
                        )}
                        {condition}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </Slider>
          <div
            className="sliderCounter"
            style={{ top: `calc(${textHeight}px + 50px)` }}
          >
            {initialSlide + 1} / {5}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelRoutes;
