"use client";

import React, { useEffect } from "react";
import styles from "./about.module.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const benefits = [
  {
    title: "Найкращі ціни",
    imageURL: "/assets/icons/advantages.svg",
  },
  {
    title: "Адресна доставка",
    imageURL: "/assets/icons/bus.svg",
  },
  {
    title: "розкладні сидіння",
    imageURL: "/assets/icons/pas.svg",
  },
  {
    title: "Два водії",
    imageURL: "/assets/icons/da.svg",
  },
  {
    title: "Знижки для дітей",
    imageURL: "/assets/icons/das.svg",
  },
  {
    title: "Розетки та WI-FI",
    imageURL: "/assets/icons/wifi.svg",
  },
];

const About = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo(
      ".benefit",
      {
        opacity: 0,
        x: 100,
        stagger: 0.2,
      },
      {
        opacity: 1,
        x: 0,
        stagger: 0.2,
        duration: 1,
        scrollTrigger: {
          trigger: ".benefit",
          start: "top 80%",
          end: "top 50%",
          toggleActions: "play none none reverse",
        },
        ease: "elastic(1, 0.75)",
      }
    );
  }, []);
  return (
    <div className={styles.about} id="about">
      <div className="container">
        <strong>Про нас</strong>
        <h2>Давайте познайомимось</h2>
        <p className={styles.about__text}>
          Наша компанія по пасажирським перевезенням працює з 2012 року.
          Спочатку це був один бус та декілька індивідуальних поїздок для групи
          людей, потім ще одне авто та підписаний контракт на регулярні поїздки
          дітей на змагання від спортивного клубу. З кожним роком, розвиваючись,
          на сьогоднішній день ми маємо автопарк з 12 сучасних автомобілей,
          більше 3000 задоволених клієнтів, досвідчений колектив та є лідерами
          на ринку транспортних перевезень в Європу.
        </p>
        <div className={styles.content}>
          <div className={styles.leftColumn}>
            <strong>Наші переваги</strong>
            <h2>Чому варто обрати саме нас?</h2>
          </div>
          <div className={styles.rightColumn}>
            {benefits.map((benefit, index) => (
              <div key={index} className={` benefit ${styles.benefit}`}>
                <img src={benefit.imageURL} alt={benefit.title} />
                <p>{benefit.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
